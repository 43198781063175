/* Router */
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Main } from './Main';
/* Components */
import { CountdownTimer } from '../components/CountdownTimer';
import { PrivateRoute } from '../components/auth/PrivateRoute';
import { PublicRoute } from '../components/auth/PublicRoute';
/* Pages */
import { Login } from '../pages/auth/Login';

// ROUTER
/* Rutas Autenticadas */
export const AuthRouter = ({ clicks }) => (
    <BrowserRouter>
        <Routes>
            {/* Rutas Públicas */}
            <Route
                element={
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                }
                path='/login'
            />

            {/* Rutas Privadas */}
            <Route
                element={
                    <PrivateRoute>
                        <CountdownTimer clicks={clicks} />
                            
                        <Main />
                    </PrivateRoute>
                }
                path='/*'
            />
        </Routes>
    </BrowserRouter>
);
