/* Router */
import { Link } from 'react-router-dom';
/* Icons */
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
/* Constants */
import { checkRoles } from '../../constants/rolesHelper';
import { mobileArray } from '../../constants/navbar/Navegation';
/* Hooks */
import { useState } from 'react';

// COMPONENTE
/* Menú Móvil */
export const MenuMobile = ({ open, setOpen, patio, roles }) => {
    // CONSTANTES
    /* Valor del Menú */
    const [ header, setHeader ] = useState(0);

    // FUNCIONES
    /* Funcionalidad de Cierre del Menú */
    const closeMobileMenu = () => {
        setOpen(!open)
        setHeader(0);
    }

    // RETORNO
    return (<>
        {mobileArray.map((item, index) => item.submenu === null
            ? checkRoles(roles, item.role) && (
                <Link
                    className='px-4 py-1 block rounded-md text-white font-bold hover:bg-snow hover:text-blue-dark'
                    key={index}
                    onClick={() => closeMobileMenu()}
                    to={item.href}
                >
                    <item.icon /> { item.name }
                </Link>
            )
            : checkRoles(roles, item.role) && (
                <div key={index}>
                    <button
                        className={`px-4 ${header === item.header ? 'pt-1 pb-2' : 'py-1'} w-full flex items-center justify-between text-white focus:outline-none`}
                        onClick={() => setHeader(header === 0 || header !== item.header ? item.header : 0)}
                    >
                        <div className='font-bold flex items-center'>
                            <item.icon /> { item.name }
                        </div>

                        {// CONDICIONAL
                        /* Comprobación de la Apertura del Submenú */
                        header === item.header
                            ? (<ChevronUpIcon className="w-6 h-6" />)
                            : (<ChevronDownIcon className="w-6 h-6" />)
                        }
                    </button>

                    {// CONDICIONAL
                    /* Comprobación de la Apertura del Submenú */
                    header === item.header && (
                        <div className='bg-blue-2 py-1 shadow-[inset_0_2px_4px_rgba(0,0,0,0.5)]'>
                            {item.submenu.map((element, idx) => (
                                <Link
                                    className='px-8 py-0.5 flex items-center rounded-md text-white text-base font-medium hover:bg-snow hover:text-blue-dark'
                                    key={idx}
                                    onClick={() => closeMobileMenu()}
                                    to={item.name === 'Facturación Manual' ? `facturacion-manual${element.href}` : element.href}
                                >
                                    <element.icon />

                                    <p className='ml-1'>{ element.name }</p>

                                    {// CONDICIONAL
                                    /* Comprobación del Nombre del Menú Igual a Facturación*/
                                    item.name === 'Facturación' && element.name !== 'Referencias' && (<div className='ml-3 px-2 py-0.5 bg-ambar rounded-full text-[0.6rem] leading-3'>{ patio.uso }</div>)}
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            )
        )}
    </>);
}
