/* Router */
import { Link } from 'react-router-dom';

// COMPONENTE
/* Botón con Texto e Icono */
export const TextIconButton = ({ clickFunction, colorBg = 'snow', colorBgHover = 'white', colorText = 'black', colorTextHover = 'black', disabled = false, enableLink = true, icon, margin= 'mt-4', path, text, title = '', type = 'button', widthButton = 28, widthDiv = 24 }) => (
    <button
        className={`px-1 ${margin} w-${widthButton} focus:outline-none`}
        disabled={disabled}
        onClick={clickFunction}
        title={title}
        type={type}
    >
        {// CONDICIONAL
        /* Comprobación del Uso para un Link */
        enableLink
            ? (
                <Link
                    className={`px-2 py-1 w-${widthDiv} rounded-full text-${colorText} bg-${colorBg} hover:text-${colorTextHover} hover:bg-${colorBgHover} flex shadow-md`}
                    to={path}
                >
                    <div className='mx-2 font-bold text-xs'>{ text }</div>

                    { icon }
                </Link>
            )
            : (
                <div className={`px-2 py-1 w-${widthDiv} rounded-full text-${colorText} bg-${colorBg} hover:text-${colorTextHover} hover:bg-${colorBgHover} flex shadow-md`}>
                    <span className='mx-2 font-bold text-xs'>{ text }</span>

                    { icon }
                </div>
            )
        }
    </button>
);
