// CONSTANTES
/* Importación de Librería de Conversión de Cantidad Numérica a Letras */
const numberToLetters = require('number-2-letters');

// HELPERS
/* Agregación de Ceros a un Número */
export const addTrailingZeros = (num, totalLength, start = false) => {
    // CONDICIONAL
    /* Comprobación de la Agregación al Principio del Número */
    if (start) return String(num).padStart(totalLength, '0');
    else return String(num).padEnd(totalLength, '0');
}

/* Formato de Números con Punto como Separador Decimal y Coma como Separador de Miles */
export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/* Formato de Números con Coma como Separador Decimal y Punto como Separador de Miles */
export const numFormat = (data) => {
    // CONDICIONAL
    /* Comprobación del Valor sobre el Tipo de Dato Igual a number y el Formato Válido como Número */
    if (typeof data === 'number' && !isNaN(data)) return new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data);
    else return new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data.replace(/,/g, ''));
}

/* Conversión de string a number */
export const parseValue = (value) => {
    // CONDICIONAL
    /* Comprobación del Valor sobre el Tipo de Dato Igual a number y el Formato Válido como Número */
    if (typeof value === 'number' && !isNaN(value)) return value;
    else return parseFloat(value.replace(/,/g, '')).toFixed(2);
}

/* Montos Totales de la Factura Expresados en Letras */
export const totalAmountLetter = (num) => {
    // VARIABLES
    let numStr = ''                                                     /* Expresión Generada */
    let entero = Math.trunc(num);                                       /* Parte Entera del Número Dado */
    let decimal = ((num - Math.trunc(num)) * 100).toFixed(0);           /* Parte Decimal del Número Dado */

    numStr = numberToLetters(entero, { lang: 'es', });

    // CONDICIONAL
    /* Comprobación de la Parte Decimal del Número Dado Diferente de Cero */
    if (decimal !== 0) numStr = `${numStr} CON ${decimal}/100`; 
    
    // RETORNO
    return numStr;
}
