import axios from 'axios';
/* Context */
import { AuthContext } from '../../components/auth/authContext';
/* Components */
import { InputField } from '../../components/inputs/InputField';
import { InputPass } from '../../components/inputs/InputPass';
import { ModalCambioInstancia } from '../../components/modals/instancia/ModalCambioInstancia';
/* Icons */
import { FcKey } from 'react-icons/fc';
import { HiMail } from 'react-icons/hi';
/* Constants */
import { endpoint } from '../../constants/endpoint';
import { images } from '../../constants/images';
import { saludo } from '../../constants/helpers';
/* Hooks */
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
/* Services */
import { headers } from '../../services/HttpHeaders';
/* Types */
import { types } from '../../types/types';
/* Headless UI */
import { Transition } from '@headlessui/react';
/* Toasts */
import { toast } from 'react-toastify';
import { toastOptions } from '../../constants/helpers';

// CONSTANTES
/* Url */
const url = `${endpoint}/auth/login`;

// COMPONENTE
/* Pie de Página del Login */
const Footer = () => (
    <footer className='bg-snow'>
        <div className='flex justify-center py-10'>
            <p className='text-base text-gray-dark'>
                Todos los Derechos Reservados @ <span className='font-bold'>Horus</span> 2022
            </p>
        </div>
    </footer>
);

// PAGINA
/* Login */
export const Login = () => {
    /* Contexto */
    const { dispatch } = useContext(AuthContext);
    /* Manejo de Datos en los Inputs y el Submit */
    const { register, handleSubmit } = useForm();

    // CONSTANTES
    const [ initialRender, setInitialRender ] = useState(false);        /* Estado de Activación del Efecto */
    const [ loading, setLoading ] = useState(false);                    /* Estado de Carga */
    const [ open, setOpen ] = useState(false);                          /* Estado de Apertura del Modal para el Cambio de Patio */
    const [ payload, setPayload ] = useState({});                       /* Payload de la Acción */

    // FUNCIONES
    /* Manejador del Submit */
    const onSubmit = async (data) => {
        setLoading(true);

        await axios.post(url, data, headers())
        .then(async (response) => {
            // CONSTANTES
            const urlDivisa = `${endpoint}/v2/tasa`;            /* Url del API de Divisa */
            const token = response.data.access_token;           /* Token */
            const gnaviUser = response.data.gnaviUser;          /* Usuario de Gnavi */
            const empresas = response.data.empresa;             /* Empresas Asociadas al Usuario */
            const patios = response.data.patio;                 /* Patios Asociados al Usuario */
            const roles = response.data.roles;                  /* Roles Asociados al Usuario */

            // CONDICIONAL
            /* Comprobación de la Inexistencia de Empresas Asociadas al Usuario */
            if (empresas.length === 0) {
                toast.warning('Upps, no tiene alguna empresa asignada. Por favor, contacte a Sistemas.', toastOptions);
                setLoading(false);
            }
            /* Comprobación de la Inexistencia de Patios Asociados al Usuario */
            else if (patios.length === 0) {
                toast.warning('Upps, no tiene algún patio asignado. Por favor, contacte a Sistemas.', toastOptions);
                setLoading(false);
            }
            else {
                // VARIABLES
                let divisa = 0;                                                                         /* Divisa */
                let basicPayload = { roles, token, user: { ...gnaviUser, empresas, patios }, }          /* Acción del Dispatch */

                await axios.get(urlDivisa, headers(token))
                .then((response) => divisa = response.data.valor)
                .catch((err) => console.log(err, 'err Divisa'));

                // CONDICIONAL
                /* Comprobación de la Existencia de un Único Patio y una Única Empresa Asociados al Usuario */
                if (patios.length === 1 && empresas.length === 1) {
                    // VARIABLES
                    /* Acción del Dispatch */
                    let action = {
                        payload: {
                            ...basicPayload,
                            divisa,
                            empresa: empresas[0],
                            patio: patios[0],
                            vacio: patios[0].uso === 'MTY' ? true : false,
                        },
                        type: types.login,
                    }

                    dispatch(action);
                    setLoading(false);
                }
                else {
                    setPayload({ ...basicPayload, divisa, });
                    setOpen(true);
                    setLoading(false);
                }
            }
        })
        .catch(() => {
            toast.error('Opps, Credenciales Inválidas', toastOptions);
            setLoading(false);
        });
    }

    useEffect(() => {
        setInitialRender(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // RETORNO
    return (
        <div className='bg-white-dark min-h-screen flex flex-col items-stretch pt-2'>
            {/* Contenido Principal (Inicio) */}
            <main className='flex flex-col items-center justify-center w-full flex-1 px-20 text-center'>
                <div className='absolute lg:hidden md:hidden z-0 inset-0 bg-no-repeat bg-cover items-center bg-gray-500'>
                    <div className='absolute bg-black opacity-60 inset-0 z-0' />
                </div>

                <Transition
                    className='bg-snow rounded-2xl shadow-2xl flex max-w-4xl sm:w-6/12 md:w-9/12 lg:w-10/12 z-10'
                    enter='transition transform duration-500 zoom-in'
                    enterFrom='translate-y-4 opacity-0'
                    enterTo='translate-y-0 opacity-100'
                    leave='transition-opacity duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                    show={initialRender}
                >
                    {/* Lado izquierdo */}
                    <div className='p-5 sm:w-full md:w-auto lg:w-3/5'>
                        <div className='text-left font-bold'>
                            <span className='text-blue'>Gnavi</span> - Facturación
                        </div>
                        <div className='mt-4 py-1'>
                            <h2 className='text-3xl font-bold text-blue mb-2'>Inicio de Sesión</h2>
                            
                            <div className='border-2 w-36 inline-block mb-1' style={{ borderColor: '#4B6587', }}></div>

                            <p className='text-blue font-semibold text-lg'>{ saludo() }</p>
                        </div>

                        {/* Formulario */}
                        <form className='w-full px-4 lg:px-0 mx-auto' onSubmit={handleSubmit(onSubmit)}>
                            <div className='pt-4 lg:mx-4'>
                                <InputField
                                    Icon={() => (<HiMail className='mt-0.5 mx-2 text-blue-light text-lg' />)}
                                    id='email'
                                    label='Email'
                                    name='email'
                                    placeholder='Ingresa tu correo electrónico...'
                                    register={register}
                                    type='text'
                                />
                            </div>
                            <div className='-mt-2 lg:mx-4'>
                                <InputPass
                                    Icon={() => (<FcKey className='mt-0.5 mx-2 text-lg' />)}
                                    id='password'
                                    label='Contraseña'
                                    name='password'
                                    placeholder='Ingresa tu contraseña...'
                                    register={register}
                                />
                            </div>

                            {/* Botones de Acciones para el Inicio de Sesión */}
                            <div className='pt-14 pb-6 lg:mx-4'>
                                {// CONDICIONAL
                                /* Comprobación del Estado de Carga */
                                loading === false
                                    ? (<button className='px-2 py-1.5 w-full bg-blue text-lg text-white font-bold rounded-full transition ease-out duration-700 hover:bg-blue-dark focus:outline-none'>Iniciar Sesión</button>)
                                    :(
                                        <button
                                            className='px-2 py-1.5 w-full flex items-center justify-center relative group bg-blue text-lg text-white font-bold rounded-full transition ease-out duration-700 hover:bg-blue-dark focus:outline-none'
                                            disabled
                                        >
                                            <div className='mx-2 w-5 h-5 border-b-2 border-white rounded-full animate-spin'></div>

                                            Cargando...
                                        </button>
                                    )
                                }
                            </div>
                        </form>
                    </div>

                    {/* Lado Derecho */}
                    <div className='lg:flex md:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center rounded-tr-2xl rounded-br-2xl text-snow'>
                        <div className='absolute bg-black opacity-60 inset-0 z-0 rounded-tr-2xl rounded-br-2xl' />
                        <div className='w-full z-10'>
                            <div className='grid place-items-center'>
                                <img alt='logo' className='mb-6 w-1/4 h-auto bg-auto lg:mb-8' src={images.logo} />
                            </div>

                            <p className='lg:text-2xl md:text-lg my-4 px-2'>Sistema de Facturación y Administración para Negocios</p>
                        </div>
                    </div>
                </Transition>
            </main>
            {/* Contenido Principal (Fin) */}

            <div className='border w-full border-gray-900 inline-block opacity-10'></div>

            {/* Footer */}
            <Footer />

            {/* Modal para el Cambio de Instancia */}
            <ModalCambioInstancia
                isLogin={true}
                open={open}
                setOpen={setOpen}
                payload={payload}
            />
        </div >
    );
}
