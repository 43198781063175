// COMPONENTE
/* Input */
export const InputField = ({className, Icon, id, label, name, placeholder, register, type }) => (<>
    <div className='flex items-start justify-start'>
        <label className='font-semibold mb-1 mt-6 text-gray-700 px-2 inline-flex' htmlFor={id}>
            {// CONDICIONAL
            /* Comprobación de la Existencia de un Icono */
            Icon && <Icon /> } 
            
            { label }
        </label>
    </div>
    <div className='relative w-full'>
        <input
            className={`${className} appearance-none rounded-2xl w-full py-3 px-3 leading-tight border-gray-400 border focus:ring-0 focus:shadow-lg focus:border-blue-500 focus:outline-none text-gray-700 pr-16 font-light`}
            id={id}
            placeholder={placeholder}
            type={type}
            {...register(`${name}`, { required: true })}
        />
    </div>
</>);
