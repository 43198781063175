// CONSTANTES
/* Colores */
export const vColors = {
    agata: '#F2C61D',
    agata2: '#DFB618',
    ambar: '#FF7E00',
    ambar2: '#F24236',
    ana: '#6716F2',
    ana2: '#E95555',
    black: '#000000',
    blue: '#4B6587',
    blue2: '#5A92D2',
    blueCobalt: '#0047AB',
    blueDark: '#2A3B4F',
    blueDark2: '#2D5094',
    blueLight: '#A5C0E2',
    brown: '#994A20',
    emerald: '#50C878',
    gray: '#C8C6C6',
    gray2: '#C6C6C8',
    grayDark: '#9B9A9A',
    grayDark2: '#878280',
    green: '#118C4F',
    green2: '#A3D47C',
    meat: '#F0E5CF',
    meatDark: '#E7DBC4',
    mint: '#32C658',
    orange: '#FF8000',
    orange2: '#FFA833',
    pink: '#FFB5C2',
    purple: '#8C004B',
    redwine: '#5E2129',
    ruby: '#C23240',
    rubydark: '#BD0909',
    snow: '#FFFFFF',
    violet: '#9064F7',
    white: '#F7F6F2',
    whiteDark: '#F7FAFC',
    whiteDarker: '#E6EBEE',
    yellow: '#F3C70D',
    yellow2: 'FFD41F',
}
