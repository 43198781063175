/* Universal Cookie */
import Cookie from 'universal-cookie';

// CONSTANTES
/* Instancia de la Cookie */
const cookie = new Cookie();

// CLASE
/* Servicio de Cookie */
class CookieServiceClass {
    // FUNCIONES
    /* Obtención */
    get(key) {
        // RETORNO
        return cookie.get(key);
    }

    /* Asignación */
    set(key, value, options) { cookie.set(key, value, options); }

    /* Remoción */
    remove(key) { cookie.remove(key); }
}

// CONSTANTES
/* Instancia de la Clase */
const CookieService = new CookieServiceClass();

export default CookieService;
