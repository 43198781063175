import { Fragment } from 'react';
/* Router */
import { Link } from 'react-router-dom';
/* Icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* Constants */
import { checkRoles } from '../../constants/rolesHelper';
import { configArray } from '../../constants/navbar/Navegation';
import { vColors } from '../../constants/vColors';
/* Headless UI */
import { Popover, Transition } from '@headlessui/react';

// COMPONENTE
/* Menú de Configuración */
export const MenuConfiguracion = ({ roles }) =>  (<>
    {// CONDICIONAL
    /* Comprobación de la Muestra de Funciones Específicas para los Roles Permitidos */
    checkRoles (roles, 5) && (<Popover>
        {({ open }) => (<>
            {/* Botón de Apertura del Menú */}
            <Popover.Button className='flex items-center justify-center bg-snow text-blue hover:bg-white px-3 py-3 rounded-md text-sm font-medium peer xl:py-2'>
                <FontAwesomeIcon className='mr-2 xl:mr-1' color={vColors.blue} icon='cogs' />

                <span className='hidden mr-2 xl:block'>Configuración</span>

                {// CONDICIONAL
                /* Comprobación del Estado de Apertura del Menú */
                open === true 
                    ? (<FontAwesomeIcon color={vColors.blue} icon='chevron-up' />) 
                    : (<FontAwesomeIcon color={vColors.blue} icon='chevron-down' />)
                }
            </Popover.Button>

            <div className="px-2 py-0.5 mt-3.5 z-10 absolute bg-blue-2 rounded-full opacity-0 text-white text-sm font-medium peer-hover:duration-300 xl:hidden peer-hover:opacity-100">Configuración</div>

            <Transition
                as={Fragment}
                enter='transition ease-out duration-400'
                enterFrom='opacity-0 translate-y-1'
                enterTo='opacity-100 translate-y-0'
                leave='transition ease-in duration-150'
                leaveFrom='opacity-100 translate-y-0'
                leaveTo='opacity-0 translate-y-1'
            >
                <Popover.Panel className='absolute z-50 w-screen max-w-sm px-4 mt-3.5 transform sm:px-0'>
                    {({ close }) => (
                        <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                            <div className='p-5 relative grid gap-6 bg-snow'>
                                {configArray().map((item, index) => checkRoles(roles, item.role) && (
                                    <Link
                                        className='flex items-center p-1.5 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-white focus:outline-none focus-visible:ring focus-visible:ring-orange focus-visible:ring-opacity-50'
                                        key={index.toString()}
                                        onClick={() => close()}
                                        to={item.href}
                                    >
                                        <div className='flex items-center justify-center flex-shrink-0 w-10 h-10 text-blue-dark sm:h-12 sm:w-12'>
                                            <item.icon aria-hidden='true' />
                                        </div>
                                        <div className='ml-2'>
                                            <p className='text-sm text-gray-darker font-bold'>{ item.name }</p>
                                            <p className='text-xs text-gray-darker'>{ item.description }</p>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    )}
                </Popover.Panel>
            </Transition>
        </>)}
    </Popover>)}
</>);
