// CONSTANTES
/* Imágenes */
export const images = {
    anulada: require('../assets/anulada.png'),
    background1: require('../assets/background1.jpg'),
    background2: require('../assets/background2.jpg'),
    contenedorIcon: require('../assets/contenedorIcon.png'),
    logo: require('../assets/logo.png'),
    gnaviText: require('../assets/gnaviText.png'),
    gonavi: require('../assets/gonavi.png'),
    gonaviCircle: require('../assets/gonaviCircle.png'),
    userAqua: require('../assets/userAqua.png'),
    userBlanco: require('../assets/userBlanco.png'),
}
