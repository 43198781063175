import { Fragment } from 'react';
/* Context */
import { AuthContext } from '../../auth/authContext';
/* Components */
import { TextIconButton } from '../../buttons/TextIconButton';
/* Icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FaArrowRight } from 'react-icons/fa';
/* Constants */
import { getArrWithoutDuplicates } from '../../../constants/helpers';
import { vColors } from '../../../constants/vColors';
/* Hooks */
import { useContext, useState } from 'react';
/* Types */
import { types } from '../../../types/types';
/* Headless UI */
import { Dialog, Transition } from '@headlessui/react';

// COMPONENTE
/* Contenido del Modal para el Cambio de Instancia */
export const ModalCambioInstanciaContent = ({ empresas, isLogin, patios, payload, setOpen }) => {
    /* Contexto */
    const { dispatch } = useContext(AuthContext);

    // VARIABLES
    let notDupEmpresas = getArrWithoutDuplicates(empresas);         /* Arreglo de las Empresas sin Duplicados */
    let notDupPatios = getArrWithoutDuplicates(patios);             /* Arreglo de los Patios sin Duplicados */

    // CONSTANTES
    const [ loading, setLoading ] = useState(false);                                /* Estado de Carga */
    const [ selectedEmpresa, setSelectedEmpresa ] = useState('');                   /* Empresa Seleccionada */
    const [ warningEmpresa, setWarningEmpresa ] = useState(false);                  /* Estado de Advertencia de Selección de una Empresa */
    const [ selectedPatio, setSelectedPatio ] = useState('');                       /* Patio Seleccionado */
    const [ warningPatio, setWarningPatio ] = useState(false);                      /* Estado de Advertencia de Selección de un Patio */
    const [ warningPatioEmpresa, setWarningPatioEmpresa ] = useState(false);        /* Estado de Advertencia de Selección de un Patio al Cambiar de Empresa */

    // FUNCIONES
    /* Lógica del Submit */
    const submitLogic = () => {
        setLoading(true);
            
        // VARIABLES
        let empresa = notDupEmpresas.length === 1 ? notDupEmpresas[0] : notDupEmpresas.find((item) => item.id === selectedEmpresa.id);                                          /* Empresa Seleccionada */
        let patio = patios.find((item) => notDupPatios.length === 1 ? item.id === selectedPatio.id : item.id === selectedPatio.id && item.empresa_id === empresa.id);           /* Patio Seleccionado */
        let vacio = patio.uso === 'MTY' ? true : false;                                                                                                                         /* Estado de Vacio del Patio Seleccionado */
        let action;                                                                                                                                                             /* Acción */

        // CONDICIONAL
        /* Comprobación del Uso para el Login */
        if (isLogin) action = { payload: { ...payload, empresa, patio, vacio, }, type: types.login, }
        else action = { payload: { empresa, patio, vacio, }, type: types.instancia, }

        setTimeout(() => {
            dispatch(action);
            setLoading(false);

            // CONDICIONAL
            /* Comprobación del Uso para el Cambio de Instancia */
            if (!isLogin) setTimeout(() => window.location.reload(), 250);
        }, 250);
    }

    /* Manejador del Submit */
    const onSubmit = () => {
        // CONDICIONAL
        /* Comprobación de la Existencia de Más de una Empresa y un Único Patio Asociados al Usuario, y la Selección de una Empresa */
        if (notDupEmpresas.length > 1 && notDupPatios.length === 1 && selectedEmpresa === '') setWarningEmpresa(true);
        /* Comprobación de la Existencia de una Única Empresa y Más de un Patio Asociados al Usuario, y la Selección de un Patio */
        else if (notDupEmpresas.length === 1 && notDupPatios.length > 1 && selectedPatio === '') setWarningPatio(true);
        /* Comprobación de la Existencia de Más de una Empresa y Más de un Patio Asociados al Usuario */
        else if (notDupEmpresas.length > 1 && notDupPatios.length > 1) {
            // CONDICIONAL
            /* Comprobación de la Selección de una Empresa y un Patio */
            if (selectedEmpresa !== '' && selectedPatio !== '') submitLogic();
            else {
                // CONDICIONAL
                /* Comprobación de la Selección de una Empresa */
                if (selectedEmpresa === '') setWarningEmpresa(true);

                // CONDICIONAL
                /* Comprobación de la Selección de un Patio */
                if (selectedPatio === '') setWarningPatio(true);
            }
        }
        else submitLogic();
    }

    // RETORNO
    return (
        <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <div className="inline-block align-bottom bg-white-dark rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <Dialog.Title className="px-5 py-4 inline-flex text-blue-dark text-xl font-bold leading-6">
                    <FontAwesomeIcon className='mr-2' color={vColors.rubydark} icon='globe' />
                    
                    {// CONDICIONAL
                    /* Comprobación del Uso para el Login */
                    isLogin ? 'Selección' : 'Cambio'}
                    
                    <span className="mx-1">de</span>
                    
                    {// CONDICIONAL
                    /* Comprobación de la Existencia de Más de un Patio y Más de una Empresa Asociados al Usuario */
                    notDupPatios.length > 1 && notDupEmpresas.length > 1
                        ? 'Patio y Empresa'
                        /* Comprobación de la Existencia de un Único Patio Asociado al Usuario */
                        : notDupPatios.length === 1
                            ? 'Empresa'
                            : 'Patio'
                    }
                </Dialog.Title>

                <hr />

                <div className='bg-snow px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                    {/* Formulario (Inicio) */}
                    <div className='grid grid-cols-6 gap-6'>
                        {// CONDICIONAL
                        /* Comprobación de la Existencia de Más de una Única Empresa Asociado al Usuario */
                        notDupEmpresas.length > 1 && (
                            <div className='col-span-6 md:col-span-3'>
                                <label className='block text-sm font-bold text-dark-blue' htmlFor='empresas'>
                                    Empresas <span className='text-ruby'>(*)</span>
                                </label>

                                <select
                                    autoComplete='empresas'
                                    className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-snow rounded-md shadow-sm focus:outline-none focus:ring-transparent focus:border-gray-400 sm:text-sm'
                                    id='empresa'
                                    onChange={(e) => {
                                        // CONDICIONAL
                                        /* Comprobación del Estado de Advertencia de Selección de una Empresa */
                                        if (warningEmpresa) setWarningEmpresa(false);

                                        // CONDICIONAL
                                        /* Comprobación de la Selección de un Patio */
                                        if (selectedPatio !== '') setWarningPatioEmpresa(true);

                                        // CONDICIONAL
                                        /* Comprobación de una Selección Válida para la Empresa */
                                        if (e.target.value === '') setSelectedEmpresa('');
                                        else setSelectedEmpresa(notDupEmpresas.find((item) => item.id === Number(e.target.value)));
                                    }}
                                >
                                    <option value=''>Seleccione...</option>

                                    {notDupEmpresas?.map((item) => (<option key={item.id.toString()} value={item.id}>{ item.nombre }</option>))}
                                </select>

                                {// CONDICIONAL
                                /* Comprobación del Estado de Advertencia de Selección de una Empresa */
                                warningEmpresa && (<div className='col-span-6 text-ruby mt-1 text-sm'>Debe seleccionar un Empresa</div>)}
                            </div>
                        )}

                        {// CONDICIONAL
                        /* Comprobación de la Existencia de Más de un Único Patio Asociado al Usuario */
                        notDupPatios.length > 1 && (
                            <div className='col-span-6 md:col-span-3'>
                                <label className='block text-sm font-bold text-dark-blue' htmlFor='patios'>
                                    Patios <span className='text-ruby'>(*)</span>
                                </label>
        
                                <select
                                    autoComplete='patios'
                                    className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-snow rounded-md shadow-sm focus:outline-none focus:ring-transparent focus:border-gray-400 sm:text-sm'
                                    disabled={notDupEmpresas.length > 1 && selectedEmpresa === ''}
                                    id='patio'
                                    onChange={(e) => {
                                        // CONDICIONAL
                                        /* Comprobación del Estado de Advertencia de Selección de un Patio */
                                        if (warningPatio) setWarningPatio(false);

                                        // CONDICIONAL
                                        /* Comprobación del Estado de Advertencia de Selección de un Patio al Cambiar de Empresa */
                                        if (warningPatioEmpresa) setWarningPatioEmpresa(false);

                                        // CONDICIONAL
                                        /* Comprobación de una Selección Válida para el Tabulador */
                                        if (e.target.value === '') setSelectedPatio('');
                                        else setSelectedPatio(patios.filter((item) => notDupEmpresas.length > 1 ? item.empresa_id === selectedEmpresa?.id : item).find((item) => item.id === Number(e.target.value)));
                                    }}
                                >
                                    <option value=''>Seleccione...</option>
        
                                    {patios?.filter((item) => notDupEmpresas.length > 1 ? item.empresa_id === selectedEmpresa.id : item).map((item) => item.id === 4 
                                        ? (<option key={item.id.toString()} value={item.id}>Patio Externo - { item.uso }</option>)
                                        : (<option key={item.id.toString()} value={item.id}>{ item.nombre } - { item.uso }</option>)
                                    )}
                                </select>

                                {// CONDICIONAL
                                /* Comprobación del Estado de Advertencia de Selección de un Patio */
                                warningPatio && (<div className='col-span-6 text-ruby mt-2 text-sm'>Debe seleccionar un Patio</div>)}

                                {// CONDICIONAL
                                /* Comprobación del Estado de Advertencia de Selección de una Empresa */
                                warningPatioEmpresa && (<div className='col-span-6 text-ruby mt-1 text-sm'>Seleccione nuevamente un Patio</div>)}
                            </div>
                        )}
                    </div>
                    {/* Formulario (Fin) */}            

                    {/* Botones de Acciones */}                                        
                    <div className='px-4 py-2 text-right sm:px-6'>
                        {// CONDICIONAL
                        /* Comprobación del Uso para el Login */
                        isLogin
                            ? (<TextIconButton
                                clickFunction={() => onSubmit()}
                                colorBg='blue'
                                colorBgHover='blue-dark'
                                colorText='white'
                                colorTextHover='white'
                                enableLink={false}
                                icon={loading
                                    ? (<div className='mx-2 w-4 h-4 border-b-2 border-white rounded-full animate-spin'></div>)
                                    : (<BsFillCheckCircleFill  className='mr-1 text-blue-light' />)
                                }
                                text={loading ? 'Cargando' : 'Seleccionar'}
                                widthButton={loading && 32}
                                widthDiv={loading && 28}
                            />)
                            : (<>
                                <TextIconButton
                                    clickFunction={() => setOpen(false)}
                                    colorBg='gray-dark'
                                    colorBgHover='gray-darker'
                                    colorText='white'
                                    colorTextHover='white'
                                    enableLink={false}
                                    icon={<FaArrowRight className='h-4 w-4' />}
                                    text='Volver'
                                    widthButton={24}
                                    widthDiv={20}
                                />
                                <TextIconButton
                                    clickFunction={() => onSubmit()}
                                    colorBg='blue'
                                    colorBgHover='blue-dark'
                                    colorText='white'
                                    colorTextHover='white'
                                    enableLink={false}
                                    icon={loading
                                        ? (<div className='mx-2 w-4 h-4 border-b-2 border-white rounded-full animate-spin'></div>)
                                        : (<FontAwesomeIcon className='mr-1' color={vColors.blueLight} icon='exchange-alt' />)
                                    }
                                    text={loading ? 'Cargando' : 'Cambiar'}
                                    widthButton={loading && 32}
                                    widthDiv={loading && 28}
                                />
                            </>)
                        }
                    </div>
                </div>
            </div>
        </Transition.Child>
    );
}
