// COMPONENTE
/* Pie de las Páginas */
export const Footer = () => (
    <footer className='max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 text-gray-dark print:hidden'>
        <div className='border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left'>
            <span className='block sm:mr-1 sm:inline'>&copy; 2021 <b>Gnavi</b>, Sistema web de Facturacion.</span>
            <span className='block sm:inline'>Todos los Derechos Reservados.</span>
        </div>
    </footer>
);
