import { Fragment } from 'react';
import axios from 'axios';
/* Context */
import { AuthContext } from './auth/authContext';
/* Components */
import { TextIconButton } from './buttons/TextIconButton';
/* Icons */
import { ExclamationIcon } from '@heroicons/react/outline';
/* Hooks */
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
/* Constants */
import { endpoint } from '../constants/endpoint';
import { getRemainingTimeUntilMsTimestamp } from '../constants/countdownTimer';
/* Services */
import CookieService from '../services/CookieService';
import { headers } from '../services/HttpHeaders';
/* Types */
import { types } from '../types/types';
/* Headless UI */
import { Dialog, Transition } from '@headlessui/react';

// CONSTANTES
/* Tiempo Restante por Default */
const defaultRemainingTime = {
    seconds: '00',
    minutes: '00',
    hours: '00'
}

// FUNCIONES
/* Manejo del Contador de Expiración de la Sesión */
const toTimestamp = (date) => Math.round(date.getTime() / 1000);

// COMPONENTE
/* Modal de Aviso sobre la Expiración de la Sesión */
export const CountdownTimer = ({ clicks }) => {
    /* Contexto */
    const { dispatch } = useContext(AuthContext);
    /* Navegación */
    const navigate = useNavigate();
    /* Referencia del Botón de Cancelar */
    const cancelButtonRef = useRef(null);

    // CONSTANTES
    const [ remainingTime, setRemainingTime ] = useState(parseInt(defaultRemainingTime));                               /* Tiempo Restante */
    const [ actualTime ] = useState(new Date());                                                                        /* Fecha Actual */
    const [ expireTime ] = useState(120 * 60000);                                                                       /* Tiempo para Agregar para que la Fecha Actual Expire */
    const [ expireActualTime, setExpireActualTime ] = useState(new Date(actualTime.getTime() + expireTime));            /* Fecha de Expiración de la Sesión */
    const [ countdownTimestampMs, setCountdownTimestampMs ] = useState(`${toTimestamp(expireActualTime)}000`);          /* Tiempo Expresado en Timestamp Unix Time */
    const [ open, setOpen ] = useState(false);                                                                          /* Estado de Apertura del Modal del Contador */

    // FUNCIONES
    /* Actualización del Tiempo Restante */
    const updateRemainingTime = (countdown) => setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
    
    /* Funcionalidad de Logout */
    const handleLogout = async () => {
        setOpen(false);

        // CONSTANTES
        const url = `${endpoint}/auth/logout`;              /* Url */
        const { token } = CookieService.get('user');        /* Token */

        await axios.post(url, {}, headers(token))
        .then(() => {
            dispatch({ type: types.logout });
            navigate('/login', { replace: true });
        })
        .catch((err) => console.log(err));
    }
    
    /* Agregación de Más Tiempo a la Sesión */
    const addMoreTime = async () => {
        // VARIABLES
        let newActualTime = new Date();                                             /* Fecha Actual */
        let newExpireTime = new Date(newActualTime.getTime() + expireTime);         /* Nuevo Tiempo de Expiración */

        setExpireActualTime(newExpireTime);
        setCountdownTimestampMs(`${toTimestamp(newExpireTime)}000`);
        setOpen(false);
    }
    
    useEffect(() => {
        // FUNCIONES
        /* Intervalo para la Actualización del Tiempo Restante */
        const intervalId = setInterval(() => updateRemainingTime(parseInt(countdownTimestampMs)), 1000);

        // RETORNO
        return () => clearInterval(intervalId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ parseInt(countdownTimestampMs) ]);

    useEffect(() => {
        // CONDICIONAL
        /* Comprobación de Segundos del Tiempo Restante Diferente de undefined */
        if (remainingTime.seconds !== undefined) {
            // CONDICIONAL
            /* Comprobación de Tiempo Restante Menor o Igual a 30 Segundos */
            if (parseInt(remainingTime.seconds) <= 30 && remainingTime.minutes === '00' && remainingTime.hours === '00') {
                // CONDICIONAL
                /* Comprobación del Estado de Apertura del Modal del Contador */
                if (open === false) setOpen(true);
            }

            // CONDICIONAL
            /* Expiración de la Sesión con un Cierre Automático */
            if (remainingTime.seconds === '00' && remainingTime.minutes === '00' && remainingTime.hours === '00') handleLogout();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ remainingTime ]);

    useEffect(() => {
        addMoreTime();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ clicks.current ]);

    // RETORNO
    return (
        <Transition.Root as={Fragment} show={open}>
            <Dialog 
                as="div" 
                className="fixed z-0 inset-0 overflow-y-auto" 
                initialFocus={cancelButtonRef} 
                onClose={setOpen}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div className="inline-block align-bottom bg-white-dark rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <Dialog.Title className="text-xl font-bold leading-6 text-blue-dark py-4 px-5 inline-flex">
                                        <ExclamationIcon aria-hidden="true" className="h-6 w-6 text-red-600 mx-2 text-ambar" /> ¡Su sesión va a expirar pronto!
                                    </Dialog.Title>

                                    <hr />

                                    <div className="bg-snow py-3 px-6">
                                        {/* Contenido */}
                                        <div className='grid grid-cols-3 gap-3'>
                                            <div className='col-span-12 text-center'>
                                                <label className="block text-sm text-gray-700 font-bold" htmlFor="message">¿Desea mantener su sesión activa?</label>
                                            </div>
                                            <div className='col-span-12 text-center'>
                                                <label htmlFor="message" className="block text-sm text-gray-700">
                                                    Segundos Restantes: <span className='text-ruby font-bold'>{ remainingTime.seconds }</span>
                                                </label>
                                            </div>
                                        </div>

                                        {/* Botones de Acciones*/}
                                        <div className='py-2 text-center'>
                                            <TextIconButton
                                                clickFunction={() => handleLogout()}
                                                colorBg='blue'
                                                colorBgHover='blue-dark'
                                                colorText='white'
                                                colorTextHover='white'
                                                enableLink={false}
                                                text={<div className='flex aling-center justify-center'>No</div>}
                                                type='submit'
                                                widthButton={16}
                                                widthDiv={12}
                                            />
                                            <TextIconButton
                                                clickFunction={() => addMoreTime()}
                                                colorBg='blue'
                                                colorBgHover='blue-dark'
                                                colorText='white'
                                                colorTextHover='white'
                                                enableLink={false}
                                                text={<div className='pl-1 flex aling-center justify-center'>Si</div>}
                                                type='submit'
                                                widthButton={16}
                                                widthDiv={12}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
