import { lazy, Suspense } from 'react';
/* Router */
import { Route, Routes } from 'react-router-dom';
const AgentesAduanaRouter = lazy(() => import('./intermediarios/AgentesAduanaRouter'));                     /* Módulo de Agentes de Aduana */
const AvisoCreditoRouter = lazy(() => import('./avisos_credito/AvisoCreditoRouter'));                       /* Módulo de Avisos de Credito */
const BancosRouter = lazy(() => import('./configuracion/BancosRouter'));                                    /* Módulo de Bancos */
const ClientesRouter = lazy(() => import('./intermediarios/ClientesRouter'));                               /* Módulo de Clientes */
const EmpresasRouter = lazy(() => import('./configuracion/EmpresasRouter'));                                /* Módulo de Empresas */
const FacturaRouter = lazy(() => import('./factura/FacturaRouter'));                                        /* Módulo de Facturas */
const FacturaManualRouter = lazy(() => import('./factura_manual/FacturaManualRouter'));                     /* Módulo de Facturación Manual */
const ImpuestoRouter = lazy(() => import('./impuestos/ImpuestoRouter'));                                    /* Módulo de Impuestos */
const PagosRouter = lazy(() => import('./configuracion/PagosRouter'));                                      /* Módulo de Métodos de Pagos */
const PatiosEmpresasUsersRouter = lazy(() => import('./configuracion/PatiosEmpresasUsersRouter'));          /* Módulo de Patios y Empresas a Usuarios */
const NotaCreditoRouter = lazy(() => import('./notas_credito/NotaCreditoRouter'));                          /* Módulo de Notas de Credito */
const PreliquidacionRouter = lazy(() => import('./preliquidacion/PreliquidacionRouter'));                   /* Módulo de Preliquidaciones */
const ReferenciasRouter = lazy(() => import('./referencias/ReferenciasRouter'));                            /* Módulo de Referencias */
const TarifasRouter = lazy(() => import('./configuracion/TarifasRouter'));                                  /* Módulo de Tarifas */
const TasaRouter = lazy(() => import('./configuracion/TasaRouter'));                                        /* Módulo de Manejo de la Tasa */
const UsersRouter = lazy(() => import('./users/UsersRouter'));                                              /* Módulo de Usuarios (No Usado) */
/* Pages */
const Home = lazy(() => import('../pages/Home'));                       /* Inicio */
const Error404 = lazy(() => import('../pages/errors/Error404'));        /* Error 404 */

// ROUTER
/* Rutas de la Aplicación */
export const AppRouter = () => (
    <Routes>
        {/* Inicio */}
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <Home />
                </Suspense>
            } 
            path='/'
        />

        {/* Módulo de Facturación */}
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <AvisoCreditoRouter />
                </Suspense>
            }
            path='avisos-credito/*'
        />
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <FacturaRouter />
                </Suspense>
            }
            path='facturas/*'
        />
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <NotaCreditoRouter />
                </Suspense>
            }
            path='notas-credito/*'
        />
        <Route 
            element={
                <Suspense fallback={<>...</>}>
                    <PreliquidacionRouter />
                </Suspense>
            }
            path='preliquidaciones/*'
        />
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <ReferenciasRouter />
                </Suspense>
            }
            path='referencias/*'
        />

        {/* Módulo de Facturación Manual */}
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <FacturaManualRouter />
                </Suspense>
            }
            path='facturacion-manual/*'
        />

        {/* Módulo de Configuración */}
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <BancosRouter />
                </Suspense>
            }
            path='bancos/*'
        />
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <EmpresasRouter />
                </Suspense>
            }
            path='empresas/*'
        />
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <PagosRouter />
                </Suspense>
            }
            path='pagos/*'
        />
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <PatiosEmpresasUsersRouter />
                </Suspense>
            }
            path='patios-empresas-usuarios/*'
        />
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <TarifasRouter />
                </Suspense>
            }
            path='tarifas/*'
        />
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <TasaRouter />
                </Suspense>
            }
            path='tasa/*'
        />

        {/* Módulo de Intermediarios */}
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <AgentesAduanaRouter />
                </Suspense>
            }
            path='agentes-aduana/*'
        />
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <ClientesRouter />
                </Suspense>
            }
            path='clientes/*'
        />

        {/* Módulo de Impuestos */}
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <ImpuestoRouter />
                </Suspense>
            }
            path='impuestos/*'
        />
        
        {/* Módulo de Usuarios */}
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <UsersRouter />
                </Suspense>
            }
            path='users/*'
        />

        {/* Error 404 */}
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <Error404 />
                </Suspense>
            }
            path='*'
        />
    </Routes>
);
