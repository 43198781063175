/* Constants */
import { parseValue } from './calculoHelper';

// CONSTANTES
/* Expresión Regular de Solo Números */
const regexNum = new RegExp(/^([0-9])*$/);

/* Colores para la Letra del Menú de Usuario */
export const colorLetra = {
    'A': 'text-ruby',
    'B': 'text-ruby',
    'C': 'text-ruby',
    'D': 'text-ruby',
    'E': 'text-ruby',
    'F': 'text-blue-2',
    'G': 'text-blue-2',
    'H': 'text-blue-2',
    'I': 'text-blue-2',
    'J': 'text-blue-2',
    'K': 'text-blue-2',
    'L': 'text-emerald',
    'M': 'text-emerald',
    'N': 'text-agata',
    'Ñ': 'text-agata',
    'O': 'text-ambar',
    'P': 'text-ambar',
    'Q': 'text-ambar',
    'R': 'text-agata',
    'S': 'text-orange-2',
    'T': 'text-orange-2',
    'U': 'text-orange-2',
    'V': 'text-orange-2',
    'W': 'text-gray-2',
    'X': 'text-gray-2',
    'Y': 'text-gray-2',
    'Z': 'text-gray-2',
}

/* Colores para la Letra Principal del Menú de Usuario */
export const colorLetraMain = {
    'A': 'bg-ruby text-snow',
    'B': 'bg-ruby text-snow',
    'C': 'bg-ruby text-snow',
    'D': 'bg-ruby text-snow',
    'E': 'bg-ruby text-snow',
    'F': 'bg-blue-2 text-snow',
    'G': 'bg-blue-2 text-snow',
    'H': 'bg-blue-2 text-snow',
    'I': 'bg-blue-2 text-snow',
    'J': 'bg-blue-2 text-snow',
    'K': 'bg-blue-2 text-snow',
    'L': 'bg-emerald text-snow',
    'M': 'bg-emerald text-snow',
    'N': 'bg-agata text-snow',
    'Ñ': 'bg-agata text-snow',
    'O': 'bg-ambar text-snow',
    'P': 'bg-ambar text-snow',
    'Q': 'bg-ambar text-snow',
    'R': 'bg-agata text-snow',
    'S': 'bg-orange-2 text-white',
    'T': 'bg-orange-2 text-white',
    'U': 'bg-orange-2 text-white',
    'V': 'bg-orange-2 text-white',
    'W': 'bg-gray-2 text-snow',
    'X': 'bg-gray-2 text-snow',
    'Y': 'bg-gray-2 text-snow',
    'Z': 'bg-gray-2 text-snow',
}

/* Condiciones de Pago de una Factura */
export const condicionesPago = [
    { disabled: false, label: 'Crédito', value: 'credito', },
    { disabled: true, label: 'Contado', value: 'contado', },
]

/* Puntos Suspensivos */
export const DOTS = '...';

/* Variables de los Meses */
const months = [
    { monthNum: '01', name: 'enero', },
    { monthNum: '02', name: 'febrero', },
    { monthNum: '03', name: 'marzo', },
    { monthNum: '04', name: 'abril', },
    { monthNum: '05', name: 'mayo', },
    { monthNum: '06', name: 'junio', },
    { monthNum: '07', name: 'julio', },
    { monthNum: '08', name: 'agosto', },
    { monthNum: '09', name: 'septiembre', },
    { monthNum: '10', name: 'octubre', },
    { monthNum: '11', name: 'noviembre', },
    { monthNum: '12', name: 'diciembre', },
]

/* Tipos de Divisas */
export const tiposDivisas = [
    { label: 'Dólar ($)', value: 'dolar', },
    { label: 'Euro (€)', value: 'euro', },
]

/* Opciones para los Toasts */
export const toastOptions = {
    autoClose: 5000,
    closeOnClick: true,
    draggable: true,
    hideProgressBar: false,
    pauseOnHover: true,
    position: 'top-right',
}

/* Opciones para los Toasts de Facturación */
export const toastOptionsFact = {
    autoClose: 1000,
    closeOnClick: true,
    draggable: true,
    hideProgressBar: false,
    pauseOnHover: true,
    position: 'top-right',
}

// FUNCIONES
/* Conversión de la Primera Letra a Minúscula */
function descapitalizeFirstLetter(string) {
    // RETORNO
    return string.charAt(0).toLowerCase() + string.slice(1); 
}

/* Obtención del Primer Key de un Array (Sinónimo de [0]) */
function getKey(data) {
    for (var prop in data)
        // RETORNO
        return prop;
}

/* Obtención de un Rango dentro de un Arreglo */
const range = (start, end) => {
    // VARIABLES
    /* Tamaño del Rango */
    let length = end - start + 1;

    // RETORNO
    return Array.from({ length }, (_, index) => index + start);
}

// HELPERS
/* Cálculo de los Gastos Adicionales para una Factura */
export const calcularGastosFactura = (tarifas, tasa) => {
    // VARIABLES
    /* Data para los Cálculos */
    let data = {
        baseImponibleBs: 0,
        baseImponibleUsd: 0,
        ivaBs: 0,
        ivaUsd: 0,
        totalBs: 0,
        totalUsd: 0
    }

    tarifas.forEach((e) => {
        data.baseImponibleBs += e.totalBs;
        data.baseImponibleUsd += e.totalUsd;
    });

    data.ivaBs = (data.baseImponibleBs * 0.16).toFixed(2);
    data.ivaUsd = (data.baseImponibleUsd * 0.16).toFixed(2);
    data.totalBs = (data.baseImponibleBs + parseFloat(parseValue(data.ivaBs))).toFixed(2);
    data.totalUsd = (data.baseImponibleUsd + parseFloat(parseValue(data.ivaUsd))).toFixed(2);
    data.tasa = tasa;

    // RETORNO
    return data;
}

/* Cálculo de los Megabytes de una Carga */
export const calculoMbs = (value) => {
    // VARIABLES
    /* Valor en Kilobytes */
    let kilobytes = value / 1024;
  
    // RETORNO
    return kilobytes >= 1024 ** 2
        ? `${(kilobytes / 1024 ** 2).toFixed(2)} GB`
        : kilobytes >= 1024
            ? `${(kilobytes / 1024).toFixed(2)} MB`
            : `${kilobytes.toFixed(2)} KB`;
}

/* Formato de la Fecha Escrita */
export const dateFormat = (date) => {
    // CONSTANTES
    /* Mes Filtrado de la Fecha Dada */
    const month = months.find((month) => date.includes(month.name));

    // RETORNO
    return date.replace(month.name, month.monthNum).replace(' de ', '/').replace(' del ', '/');
}

/* Obtención de un Arreglo sin Duplicados */
export const getArrWithoutDuplicates = (arrOg) => {
    // VARIABLES
    /* Arreglo sin Duplicados */
    let newArr = []

    arrOg.forEach((item) => {
        // VARIABLES
        /* Index del Ciclo */
        let i = 0;

        // CONDICIONAL
        /* Comprobación del Tamaño del Arreglo sin Duplicados Diferente de 0 */
        if (newArr.length !== 0) {
            for (i; i < newArr.length; i++) {
                // VARIABLES
                /* Estado de Agregado del Item */
                let added = false;

                // CONDICIONAL
                /* Comprobación del id del Item Igual que el Elemento Actual del Arreglo sin Duplicados */
                if (item.id === newArr[i].id) added = true;

                // CONDICIONAL
                /* Comprobación del Index Igual al Tamaño Menos 1 del Arreglo sin Duplicados y el Estado de Agregado del Item */
                if ((i === newArr.length - 1) && !added) newArr.push(item);
            }
        }
        else newArr.push(item);
    });

    // RETORNO
    return newArr;
}

/* Obtención de los Bancos para la Preliquidacion */
export const getBancosPreliquidacion = (emissionDate, patio) => {
    // VARIABLES
    let banks = []                                      /* Bancos Correspondientes */
    let splitFecha = emissionDate.split('-');           /* Fecha Dividida */

   // CONDICIONAL
    /* Comprobación de la Fecha de Emisión Menor que la Fecha 08-04-2024 */
    if (new Date(splitFecha[2], splitFecha[1], splitFecha[0]) < new Date(2024, 4, 8)) {
        banks = [
            'BANCO DE VENEZUELA: 0102-0475-58-0000189167',
            'BANCO FONDO COMÚN: 0151-0162-83-1000806663',
            'BANCO NACIONAL DE CRÉDITO: 0191-0056-16-2156088346',
            'BANPLUS: 0174-0134-37-1344004229',
        ]
    }
    else {
        // CONDICIONAL
        /* Comprobación del Patio de la Preliquidación */
        switch (patio) {
            case 21:
                banks = [ 'BANCO BICENTENARIO: 0175-0190-94-0076871940', ]

                break;
            case 4:
                banks = [ 'BANCO BICENTENARIO: 0175-0248-45-0071851179', ]
    
                break;
        
            default:
                banks = [
                    'BANCO DE VENEZUELA: 0102-0475-58-0000189167',
                    'BANCO FONDO COMÚN: 0151-0162-83-1000806663',
                    'BANCO NACIONAL DE CRÉDITO: 0191-0056-16-2156088346',
                    'BANPLUS: 0174-0134-37-1344004229',
                ]

                break;
        }
    }

    // RETORNO
    return banks;
}

/* Obtención de la Data Paginada */
export const getPaginateData = (data, setData) => {
    // CONSTANTES
    const { current_page, first_page_url, from, last_page, last_page_url, links, next_page_url, path, per_page, prev_page_url, to, total } = data;          /* Desestructuración de la Data */
    const result = {                                                                                                                                        /* Resultado para la Asignación */
        current_page: current_page,
        first_page_url: first_page_url,
        from: from,
        last_page: last_page,
        last_page_url: last_page_url,
        links: links,
        next_page_url: next_page_url,
        path: path,
        per_page: per_page,
        prev_page_url: prev_page_url,
        to: to,
        total: total
    }

    setData(result);
}

/* Obtención de Información del Criterio de la Búsqueda */
export const getTypeInfo = (type, isFactura = true) => {
    // VARIABLES
    let message = `Opps, no se encuentra una ${isFactura ? 'factura' : 'preliquidación'}`;          /* Mensaje */
    let name = '';                                                                                  /* Nombre del Criterio */

    // CONDICIONAL
    /* Comprobación del Tipo de Criterio Dado */
    switch (type) {
        case 1:
            // CONDICIONAL
            /* Comprobación del Uso para una Factura */
            if (isFactura) {
                message = message + ' con ese N° de Factura';
                name = 'Factura';
            }
            else {
                message = message + ' con ese N° de Preliquidación';
                name = 'Preliquidación';
            }

            break;

        case 2:
            message = message + ' con ese N° de Acta';
            name = 'Acta';

            break;
            
        case 3:
            message = message + ' con ese BL';
            name = 'BL';

            break;

        case 4:
            message = message + ' de ese Contenedor';
            name = 'Contenedor';

            break;

        case 5:
            message = message + ' de ese Cliente';
            name = 'Cliente';

            break;
    
        default:
            break;
    }

    // RETORNO
    return { message, name }
}

/* Ordenamiento Descendente del Arreglo de un Response */
export const orderDesc = (array) => array.sort((a, b) => {
    // CONDICIONAL
    /* Comprobación del id del Primer Argumento Mayor que el id del Segundo Argumento */
    if (a.id > b.id) return -1;

    // CONDICIONAL
    /* Comprobación del id del Primer Argumento Menor que el id del Segundo Argumento */
    if (a.id < b.id) return 1;

    // RETORNO
    return 0;
});

/* Ordenamiento Ascendente por Nombre del Arreglo de un Response */
export const orderAscName = (array) => array.sort((a, b) => {
    // CONDICIONAL
    /* Comprobación del id del Primer Argumento Mayor que el id del Segundo Argumento */
    if (a.nombre < b.nombre) return -1;

    // CONDICIONAL
    /* Comprobación del id del Primer Argumento Menor que el id del Segundo Argumento */
    if (a.nombre > b.nombre) return 1;

    // RETORNO
    return 0;
});

/* Saludo */
export const saludo = () => {
    // VARIABLES
    /* Hora Actual */
    let horas = new Date().getHours();

    // CONDICIONAL
    /* Comprobación de la Hora Actual Mayor que Cuatro y Menor o Igual que Doce */
    if (horas > 4 && horas <= 12) return 'Buenos días';
    
    // CONDICIONAL
    /* Comprobación de la Hora Actual Mayor que Doce y Menor o Igual que Diecinueve */
    if (horas > 12 && horas <= 19) return 'Buenas tardes';
    
    // RETORNO
    return 'Buenas noches';
}

/* Formato de Telefono */
export const setFormatPhone = (phone) => {
    phone = phone.replace(/ /g, '').replace(/-/g, '');

    // VARIABLES
    /* Teléfono con el Formato */
    let formatPhone = '';

    for (let i = 0; i < phone.length; i ++) {
        // CONDICIONAL
        /* Comprobación del Index Igual a Cinco */
        if (i === 4) formatPhone += '-' + phone[i];
        else formatPhone += phone[i];
    }

    // RETORNO
    return formatPhone;
}

/* Formato de RIF */
export const setFormatRif = (rif) => {
    rif = rif.replace(/ /g, '').replace(/-/g, '');

    // VARIABLES
    /* RIF con Formato */
    let formatRif = '';

    for (let i = 0; i < rif.length; i ++) {
        // CONDICIONAL
        /* Comprobación del Index Igual a 0 */
        if (i !== 0) {
            // CONDICIONAL
            /* Comprobación del Index Igual al Tamaño del RIF Menos 1 */
            if (i === rif.length - 1) formatRif += '-' + rif[i];
            /* Comprobación del Elemento Actual siendo el Primer Número del RIF */
            else if (regexNum.test(rif[i]) && !regexNum.test(rif[i - 1])) formatRif += '-' + rif[i];
            else formatRif += rif[i];
        }
        else formatRif += rif[i];
    }

    // RETORNO
    return formatRif.toUpperCase();
}

/* Formato de Horas 12h */
export const setFormatTime12 = (time) => {
    // VARIABLES
    let splitTime = time.split(':');        /* Arreglo con la Hora y los Minutos */
    let formatTime = '';                    /* Hora 24h con el Formato 12h */

    // CONDICIONAL
    /* Comprobación de la Hora Mayor o Igual a 12 */
    if (Number(splitTime[0]) >= 12) formatTime = `${Number(splitTime[0]) - 12}:${splitTime[1]} pm`;
    else formatTime = `${time} am`;

    // RETORNO
    return formatTime;
}

/* Obtención de la Página de una Tabla */
export const sliceTable = (data, page, pageSize) => {
    // CONSTANTES
    const firstPageIndex = (page - 1) * pageSize;           /* Index del Primer Registro de la Página */
    const lastPageIndex = firstPageIndex + pageSize;        /* Index del Último Registro de la Página */

    // RETORNO
    return data.slice(firstPageIndex, lastPageIndex);
}

/* Obtención del Rango de una Página de una Tabla */
export const paginationRange = (dataQty, pageSize, siblingCount = 1, currentPage) => {
    // COSNTANTES
    const pagesQty = Math.ceil(dataQty / pageSize);         /* Cantidad de Páginas */
    const pageNumbers = siblingCount + 5;                   /* Cantidad de Página Mostradas en los Botones de Paginación */

    // CONDICIONAL
    /* Comprobación de la Cantidad de Página Mostradas en los Botones de Paginación Mayor o Igual a la Cantidad de Páginas */
    if (pageNumbers >= pagesQty) return range(1, pagesQty);

    // CONSTANTES
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);                   /* Index de la Página Cercana a los Puntos Suspensivos de la Izquierda */
    const rightSiblingIndex = Math.min(currentPage + siblingCount, pagesQty);           /* Index de la Página Cercana a los Puntos Suspensivos de la Derecha */
    const lastPageIndex = pagesQty;                                                     /* Index de la Última Página */
    const shouldShowLeftDots = leftSiblingIndex > 2;                                    /* Condición para la Muestra de Puntos Suspensivos a la Izquierda */
    const shouldShowRightDots = rightSiblingIndex < pagesQty - 2;                       /* Condición para la Muestra de Puntos Suspensivos a la Derecha */

    // CONDICIONAL
    /* Validación del Cumplimiento para la Derecha y el Incumplimiento para la Izquierda */
    if (!shouldShowLeftDots && shouldShowRightDots) {
        // VARIABLES
        let leftItemCount = 3 + 2 * siblingCount;           /* Contador para la Izquierda */
        let leftRange = range(1, leftItemCount);            /* Rango Izquierdo */
  
        // RETORNO
        return [ ...leftRange, DOTS, pagesQty ]
    }
  
    // CONDICIONAL
    /* Validación del Cumplimiento para la Izquierda y el Incumplimiento para la Derecha */
    if (shouldShowLeftDots && !shouldShowRightDots) {
        // VARIABLES
        let rightItemCount = 3 + 2 * siblingCount;                              /* Contador para la Derecha */
        let rightRange = range(pagesQty - rightItemCount + 1, pagesQty);        /* Rango Derecho */
        
        // RETORNO
        return [ 1, DOTS, ...rightRange ]
    }
  
    // CONDICIONAL
    /* Validación del Cumplimiento para la Derecha y la Izquierda */
    if (shouldShowLeftDots && shouldShowRightDots) {
        // VARIABLES
        /* Rango Medio */
        let middleRange = range(leftSiblingIndex, rightSiblingIndex);

        // RETORNO
        return [ 1, DOTS, ...middleRange, DOTS, lastPageIndex ]
    }
}

/* Validación del Response del Error del Backend */
export const validError = (err) => {
    // CONSTANTES
    /* Propiedad del Primer Elemento del Arreglo */
    const first = getKey(err.data.data)
    
    // VARIABLES
    /* Mensaje de Error */
    let error = ''

    // CONDICIONAL
    /* Comprobación del Arreglo Diferente de undefined */
    if (err.data.data !== undefined) error = descapitalizeFirstLetter(err.data.data[first][0]);

    // RETORNO
    return `${error}`;
}
