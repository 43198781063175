/* Types */
import { types } from '../../types/types';

// REDUCER
/* Autenticación */
export const authReducer = (state = {}, action) => {
    // CONDICIONAL
    /* Comprobación del Tipo de Acción */
    switch (action.type) {
        case types.instancia:
            // RETORNO
            return {
                ...state,
                empresa: action.payload.empresa,
                patio: action.payload.patio,
                vacio: action.payload.vacio,
            }
            
        case types.login:
            // RETORNO
            return {
                ...action.payload,
                logged: true,
            }

        case types.logout:
            // RETORNO
            return { logged: false }

        case types.tasa:
            // RETORNO
            return {
                ...state,
                divisa: action.payload.divisa,
            }

        default:
            // RETORNO
            return state;
    }
}
