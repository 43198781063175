/* Context */
import { AuthContext } from './authContext';
/* Hooks */
import { useContext } from 'react';
/* Router */
import { Navigate } from 'react-router-dom';

// COMPONENTE
/* Router de Rutas Públicas */
export const PublicRoute = ({ children }) => {
    /* Contexto */
    const { user } = useContext(AuthContext);

    // RETORNO
    return user.logged ? <Navigate to={`/`} /> : children;
}
