/* Icons */
import { GoEye, GoEyeClosed } from 'react-icons/go';
/* Hooks */
import { useState } from 'react';

// COMPONENTE
/* Input para Contraseña */
export const InputPass = ({ register, name, id, placeholder, label, Icon }) => {
    // CONSTANTES
    /* Estado de Muestra de la Contraseña */  
    const [ show, setShow ] = useState(false);              

    // RETORNO
    return (<>
        <div className='flex items-start justify-start'>
            <label className='font-semibold mb-1 mt-6 text-gray-700 px-2 inline-flex' htmlFor={id}>
                {// CONDICIONAL
                /* Comprobación de la Existencia de un Icono */
                Icon && <Icon /> } 
                
                { label }
            </label>
        </div>
        <div className='relative w-full'>
            {/* Botones de Acciones para la Muestra de la Contraseaña */}
            <div className='absolute inset-y-0 right-0 flex items-center px-2 transition-all'>
                {// CONDICIONAL
                /* Comprobación del Estado de Muestra de la Contraseña */
                show
                    ? (
                        <button
                            className='mr-2'
                            onClick={() => setShow(false)}
                            type='button'
                        >
                            <GoEye className='text-cyan text-xl' />
                        </button>
                    )
                    : (
                        <button
                            className='mr-2'
                            onClick={() => setShow(true)}
                            type='button'
                        >
                            <GoEyeClosed className='text-cyan text-xl' />
                        </button>
                    )
                }
            </div>

            <input
                autoComplete='off'
                className='appearance-none rounded-2xl w-full py-3 px-3 leading-tight border-gray-400 border focus:ring-0 focus:shadow-lg focus:border-blue-500 focus:outline-none text-gray-700 pr-16 font-light'
                id={id}
                placeholder={placeholder}
                type={`${show ? 'text' : 'password'}`}
                {...register(`${name}`, { required: true })}
            />
        </div>
    </>);
}
