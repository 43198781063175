import axios from 'axios';
/* Context */
import { AuthContext } from '../components/auth/authContext';
/* Router */
import { AppRouter } from './AppRouter';
/* Components */
import { Navbar } from '../components/navbar/Navbar';
import { Footer } from '../components/Footer';
/* Constants */
import { endpoint } from '../constants/endpoint';
/* Hooks */
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
/* Services */
import CookieService from '../services/CookieService';
import { headers } from '../services/HttpHeaders';
/* Types */
import { types } from '../types/types';

// ROUTER
/* Rutas Principales */
export const Main = () => {
    /* Contexto */
    const { dispatch } = useContext(AuthContext);
    /* Navegación */
    const navigate = useNavigate();

    // FUNCIONES
    /* Manejador del Logout */
    const handleLogout = async () => {
        /* Contexto */
        const { token } = CookieService.get('user');

        // CONSTANTES
        /* Url */
        const url = `${endpoint}/auth/logout`;

        await axios.post(url, {}, headers(token))
        .then(() => {
            dispatch({ type: types.logout });
            navigate('/login', { replace: true, });
        })
        .catch((err) => console.log(err));
    }

    // RETORNO
    return (<>
        <Navbar handleLogout={handleLogout} />

        <AppRouter />

        <Footer />
    </>);
}
