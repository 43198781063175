// CONSTANTES
/* Id de los Roles del Sistema */
const allRoles = [ 1, 9, 10, 11, 12, 13, ]

// FUNCIONES
/* Obtención de los Roles con Acceso */
export const getRolesAccess = (code) => {
    // VARIABLES
    /* Roles Permitidos */
    let rolesAccess;

    // CONDICIONAL
    /* Comprobación del Código */
    switch (code) {
        case 1:
            /* Super-Admin ~ Gerente-Adm */
            rolesAccess = allRoles.filter((item) => item === 1 || item === 11);
            break;

        case 2:
            /* Super-Admin ~ Gerente-Adm ~ Coord-Adm */
            rolesAccess = allRoles.filter((item) => item !== 9 && item !== 12 && item !== 13);
            break;
            
        case 3:
            /* Super-Admin ~ Gerente-Adm ~ Coord-Adm ~ Taquilla-Manual */
            rolesAccess = allRoles.filter((item) => item !== 9 && item !== 13);
            break;
            
        case 4:
            /* Super-Admin ~ Gerente-Adm ~ Coord-Adm ~ Taquilla */
            rolesAccess = allRoles.filter((item) => item !== 12 && item !== 13);
            break;

        case 5:
            /* Todos los Roles */
            rolesAccess = allRoles;
            break;

        case 6:
            /* Taquilla-Manual */
            rolesAccess = allRoles.filter((item) => item === 12);
            break;

        case 7:
            /* Super-Admin ~ Gerente-Adm ~ Coord-Adm ~ Read-Reportes */
            rolesAccess = allRoles.filter((item) => item !== 9 && item !== 12);
            break;

        case 8:
            /* Super-Admin ~ Gerente-Adm ~ Coord-Adm ~ Taquilla ~ Read-Reportes */
            rolesAccess = allRoles.filter((item) => item !== 12);
            break;

        default:
            /* Super-Admin */
            rolesAccess = allRoles.filter((item) => item === 1);
            break;
    }

    // RETORNO
    return rolesAccess;
}

/* Muestra de Funciones Específicas para los Roles Permitidos */
export const checkRoles = (userRoles, code) => {
    for (let i = 0; i < userRoles.length; i++) {
        // VARIABLES
        /* Validación */
        let check = getRolesAccess(code).includes(userRoles[i].id);

        // CONDICIONAL
        /* Comprobación de la Validación */
        if (check) return true;
    }

    // RETORNO
    return false;
}
