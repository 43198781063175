import React from 'react';
import { createRoot } from 'react-dom/client';
/* Application */
import { Gnavi } from './Gnavi';
/* Styles */
import './index.scss';

// CONSTANTES
const container = document.getElementById('root');          /* Contenedor de la Raíz */
const root = createRoot(container);                         /* Raíz de la Aplicación */

root.render(<Gnavi />);
