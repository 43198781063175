import { Fragment } from 'react';
/* Router */
import { Link } from 'react-router-dom';
/* Icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TbFileDollar } from 'react-icons/tb';
/* Constants */
import { checkRoles } from '../../constants/rolesHelper';
import { factManualArray } from '../../constants/navbar/Navegation';
import { vColors } from '../../constants/vColors';
/* Headless UI */
import { Menu, Transition } from '@headlessui/react';

// COMPONENTE
/* Menú de Facturación Manual */
export const MenuFacturacionManual = ({ roles }) =>(<> 
    {// CONDICIONAL
    /* Comprobación de la Muestra de Funciones Específicas para los Roles Permitidos */
    checkRoles(roles, 3) && (
        <div className='text-right top-16'>
            <Menu as='div' className='relative inline-block text-left'>
                {({ open }) => (<>
                    {/* Botón de Apertura del Menú */}
                    <Menu.Button className='flex items-center justify-center bg-snow text-blue hover:bg-white px-3 py-2 rounded-md text-sm font-medium peer'>
                        <TbFileDollar className='mr-2 w-5 h-5 lg:mr-1' color={vColors.blue} icon='calculator' />

                        <span className='hidden mr-2 whitespace-nowrap lg:block'>Fact. Manual</span>

                        {// CONDICIONAL
                        /* Comprobación del Estado de Apertura del Menú */
                        open === true 
                            ? (<FontAwesomeIcon color={vColors.blue} icon='chevron-up' />)
                            : (<FontAwesomeIcon color={vColors.blue} icon='chevron-down' />)
                        }
                    </Menu.Button>

                    <div className="px-2 py-0.5 mt-3.5 z-10 absolute bg-blue-2 rounded-full opacity-0 text-white text-sm font-medium whitespace-nowrap peer-hover:duration-300 lg:hidden peer-hover:opacity-100">Fact. Manual</div>

                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                    >
                        <Menu.Items className='z-50 absolute w-56 mt-3.5 origin-top-right bg-snow divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                            <div className='px-1 py-1 '>
                                {factManualArray.map((item, index) => (
                                    <Menu.Item key={index.toString()}>
                                        {({ active }) => (
                                            <Link 
                                                className={`${active ? 'bg-white text-blue' : 'text-gray-darker'} group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                                to={`facturacion-manual${item.href}`}
                                            >
                                                <div className='w-5 h-5 mr-2'>
                                                    <item.icon aria-hidden='true' />
                                                </div>

                                                { item.name }
                                            </Link>
                                        )}
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>)}
            </Menu>
        </div>
    )}
</>);
