/* Context */
import { AuthContext } from './authContext';
/* Hooks */
import { useContext } from 'react';
/* Router */
import { Navigate } from 'react-router-dom';

// COMPONENTE
/* Router de Rutas Privadas */
export const PrivateRoute = ({ children }) => {
    /* Contexto */
    const { user } = useContext(AuthContext);

    // RETORNO
    return user.logged ? children : <Navigate to='/login' />
}
