import { Fragment } from 'react';
/* Components */
import { TextIconButton } from '../buttons/TextIconButton';
/* Icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdPlace } from 'react-icons/md';
/* Constants */
import { colorLetra, colorLetraMain, getArrWithoutDuplicates } from '../../constants/helpers';
import { vColors } from '../../constants/vColors';
/* Hooks */
import { useState } from 'react';
/* Headless UI */
import { Menu, Transition } from '@headlessui/react';

// COMPONENTE
/* Menú de Usuario */
export const MenuUser = ({ data, handleLogout, open, setOpen }) => {
    // VARIABLES
    let notDupEmpresas = getArrWithoutDuplicates(data.user.empresas);           /* Arreglo de las Empresas sin Duplicados */
    let notDupPatios = getArrWithoutDuplicates(data.user.patios);               /* Arreglo de los Patios sin Duplicados */

    // CONSTANTES
    /* Estado de Apertura del Menú */
    const [ openMain, setOpenMain ] = useState(false);

    // FUNCIONES
    /* Funcionalidad de Apertura del Modal */
    const handleOpenModals = () => {
        setOpen(!open);
        setOpenMain(false);
    }

    // RETORNO
    return (<>
        <Menu as='div' className='relative inline-block text-left'>
            {/* Botón de Apertura del Menú */}
            <button className='max-w-xs bg-snow text rounded-full flex items-center text-sm' onClick={() => setOpenMain(!openMain)}>
                <span className='sr-only'>Menu de Usuario</span>

                <div className={`${colorLetra[ data.user.name.charAt(0).toUpperCase() ]} flex items-center justify-center h-8 w-8 rounded-full font-bold text-xl`}>{ data.user.name.charAt(0).toUpperCase() }</div>
            </button>

            <Transition
                as={Fragment}
                show={openMain}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
            >
                <Menu.Items className='z-50 origin-top-right absolute right-0 mt-4 w-52 rounded-lg shadow-lg py-fit bg-snow ring-1 ring-black ring-opacity-5 focus:outline-none' static>
                    <div className='block p-fit mt-2 text-center rounded-xl bg-snow'>
                        <div className={`${colorLetraMain[data.user.name.charAt(0).toUpperCase()]} h-20 w-20 text-5xl flex items-center content-center justify-center rounded-full mx-auto`}>{ data.user.name.charAt(0).toUpperCase() }</div>

                        <p className='px-3 pt-4 pb-2 text-lg font-semibold'>{ data.user.name }</p>
                        <p className='px-3 mt-1 text-xs text-black'>
                            {// CONDICIONAL
                            /* Comprobación de la Existencia del Correo Electrónico */
                            data.user.email === null 
                                ? 'No posee email'
                                : (<><FontAwesomeIcon className='mr-1.5' color={vColors.meatDark} icon='envelope' /> { data.user.email }</>)
                            }
                        </p>
                        <p className='px-3 mt-1 text-xs text-black'>
                            {// CONDICIONAL
                            /* Comprobación de la Existencia de una Empresa */
                            data.empresa === undefined || data.empresa === null
                                ? 'No posee empresa'
                                : (<><FontAwesomeIcon className='mr-1.5' color={vColors.gray2} icon='building' />  { data.empresa.nombre }</>)
                            }
                        </p>
                        <p className={`px-3 mt-1 ${!(notDupEmpresas.length > 1 || notDupPatios.length > 1) && 'mb-4'} text-xs text-black`}>
                            {// CONDICIONAL
                            /* Comprobación de la Existencia de un Patio */
                            data.patio === undefined || data.patio === null
                                ? 'No posee patio'
                                : (<span className='flex items-center justify-center'>
                                    <MdPlace className='mr-1 w-3.5 h-3.5' color={vColors.blueCobalt} />  { data.patio.id === 4 ? 'Patio Externo' : data.patio.nombre } - { data.patio.uso }
                                </span>)
                            }
                        </p>

                        {// CONDICIONAL
                        /* Comprobación de la Existencia de Más de un Patio o Más de una Empresa Asociados al Usuario */
                        (notDupPatios.length > 1 || notDupEmpresas.length > 1) && (
                            <div className='mb-4'>
                                {/* Botón de Acción para el Cambio de Instancia */}
                                <TextIconButton
                                    clickFunction={() => handleOpenModals()}
                                    colorBg='white'
                                    colorBgHover='white-dark'
                                    enableLink={false}
                                    icon={<FontAwesomeIcon color={vColors.rubydark} icon='globe' />}
                                    text={
                                        // CONDICIONAL
                                        /* Comprobación de la Existencia de Más de un Patio y Más de una Empresa Asociados al Usuario */
                                        notDupPatios.length > 1 && notDupEmpresas.length > 1
                                            ? 'Cambio de Instancia'
                                            /* Comprobación de la Existencia de un Único Patio Asociado al Usuario */
                                            : notDupPatios.length === 1
                                                ? 'Cambio de Empresa'
                                                : 'Cambio de Patio'
                                        }
                                    widthButton={
                                        // CONDICIONAL
                                        /* Comprobación de la Existencia de Más de un Patio y Más de una Empresa Asociados al Usuario */
                                        notDupPatios.length > 1 && notDupEmpresas.length > 1
                                            ? '[10.5rem]'
                                            /* Comprobación de la Existencia de un Único Patio Asociado al Usuario */
                                            : notDupPatios.length === 1
                                                ? '[10.5rem]'
                                                : 40
                                    }
                                    widthDiv={// CONDICIONAL
                                        /* Comprobación de la Existencia de Más de un Patio y Más de una Empresa Asociados al Usuario */
                                        notDupPatios.length > 1 && notDupEmpresas.length > 1
                                            ? '[9.5rem]'
                                            /* Comprobación de la Existencia de un Único Patio Asociado al Usuario */
                                            : notDupPatios.length === 1
                                                ? '[9.5rem]'
                                                : 36
                                    }
                                />
                            </div>
                        )}

                        <hr />

                        <Menu.Item>
                            {({ active }) => (
                                /* Botón de Acción */
                                <button
                                    className={`block w-full text-left px-4 py-2 text-sm text-ruby hover:bg-ruby-dark hover:text-snow hover:rounded-b-md font-bold ease-linear transition-all duration-50`}
                                    onClick={() => handleLogout()}
                                    type='button'
                                >
                                    <FontAwesomeIcon className='mx-1.5' color={`${active ? vColors.snow : vColors.rubydark}`} icon='sign-out-alt' /> Salir
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    </>);
}
