// FUNCIONES
/* Headers */
export const headers = (token, body = null) => {
    // VARIABLES
    /* Cuerpo de los Headers */
    let headerBody = {
        headers: {
            Accept: 'application/json',
        }
    }

    // CONDICIONAL
    /* Comprobación de la Existencia de un Token */
    if (token) headerBody.headers = { ...headerBody.headers, 'Authorization': `Bearer ${token}` }

    // CONDICIONAL
    /* Comprobación de la Existencia de un Cuerpo */
    if (body) Object.assign(headerBody.headers, { ...body });

    // RETORNO
    return headerBody;
}
