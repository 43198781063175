/* Day.js */
import dayjs from 'dayjs';

// UTILS
/* Agregación de Ceros para el Formato */
const padWithZeros = (number, minLength) => {
    // CONSTANTES
    /* Número Dado como String */
    const numberString = number.toString();

    // CONDICIONAL
    /* Comprobación del Tamaño del Número como String contra el Tamaño Mínimo del Formato */
    if (numberString.length >= minLength) return numberString;

    // RETORNO
    return "0".repeat(minLength - numberString.length) +  numberString;
}

/* Obtención de los Segundos Restantes */
const getRemainingSeconds = (nowDayjs, timestampDayjs) => {
    // CONSTANTES
    /* Cálculo de los Segundos entre el Timestamp Dado y el Actual */
    const seconds = timestampDayjs.diff(nowDayjs, 'seconds') % 60;

    // RETORNO
    return padWithZeros(seconds, 2);
}

/* Obtención de los Minutos Restantes */
const getRemainingMinutes = (nowDayjs, timestampDayjs) => {
    // CONSTANTES
    /* Cálculo de los Minutos entre el Timestamp Dado y el Actual */
    const minutes = timestampDayjs.diff(nowDayjs, 'minutes') % 60;

    // RETORNO
    return padWithZeros(minutes, 2);
}

/* Obtención de las Horas Restantes */
function getRemainingHours(nowDayjs, timestampDayjs) {
    // CONSTANTES
    /* Cálculo de las Horas entre el Timestamp Dado y el Actual */
    const hours = timestampDayjs.diff(nowDayjs, 'hours') % 24;

    // RETORNO
    return padWithZeros(hours, 2);
}

/* Obtención del Tiempo Restantes hasta el Timestamp */
export const getRemainingTimeUntilMsTimestamp = (timestampMs) => {
    // CONSTANTES
    const timestampDayjs = dayjs(timestampMs);          /* Timestamp Dado con Formato */
    const nowDayjs = dayjs();                           /* Timestamp Actual con Formato */

    // CONDICIONAL
    /* Comprobación de que el Timestamp Dado es Antes del Actual*/
    if (timestampDayjs.isBefore(nowDayjs)) return {
        seconds: '00',
        minutes: '00',
        hours: '00'
    }

    // RETORNO
    return {
        seconds : getRemainingSeconds(nowDayjs, timestampDayjs),
        minutes : getRemainingMinutes(nowDayjs, timestampDayjs),
        hours : getRemainingHours(nowDayjs, timestampDayjs)
    }
}
