/* Router */
import { Link } from 'react-router-dom';
/* Components */
import { MenuConfiguracion } from './MenuConfiguracion';
import { MenuFacturacion } from './MenuFacturacion';
import { MenuFacturacionManual } from './MenuFacturacionManual';
import { MenuIntermediarios } from './MenuIntermediarios';
import { MenuMobile } from './MenuMobile';
import { MenuUser } from './MenuUser';
import { ModalCambioInstancia } from '../modals/instancia/ModalCambioInstancia';
/* Icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { MdPayments, MdPlace } from 'react-icons/md';
/* Constants */
import { checkRoles } from '../../constants/rolesHelper';
import { images } from '../../constants/images';
import { numFormat } from '../../constants/calculoHelper';
import { vColors } from '../../constants/vColors';
import { userImg } from '../../constants/navbar/Navegation';
/* Hooks */
import { useState } from 'react';
/* Services */
import CookieService from '../../services/CookieService';

// COMPONENTE
/* Barra de Navegación */
export const Navbar = ({ handleLogout }) => {
    /* Contexto */
    const data = CookieService.get('user');
    const { divisa, patio, roles, user } = data;

    // CONSTANTES
    const [ openMobile, setOpenMobile ] = useState(false);          /* Estado de Apertura del Menú Móvil */
    const [ openModal, setOpenModal ] = useState(false);            /* Estado de Apertura del Modal */

    // RETORNO
    return (<>
        <div className='font-serif print:hidden'>
            <nav className='px-6 bg-blue-dark md:px-0'>
                <div className='mx-auto px-4 sm:px-6 lg:px-10'>
                    {/* Escritorio (Inicio) */}
                    <div className='flex items-center justify-between h-16'>
                        <div className='flex items-center'>
                            {/* Logo */}
                            <div className='flex-shrink-0'>
                                <Link to='/'>
                                    <img
                                        alt='Usuario'
                                        className='w-auto h-10'
                                        src={images.logo}
                                    />
                                </Link>
                            </div>
                            <div className='hidden md:block'>
                                <div className='ml-10 flex items-center space-x-4'>
                                    {/* Menu de Facturación */}
                                    <MenuFacturacion data={data} roles={roles} />

                                    {/* Menu de Facturación Manual */}
                                    <MenuFacturacionManual roles={roles} />

                                    {/* Menu de Configuración */}
                                    <MenuConfiguracion roles={roles} />

                                    {/* Menú de Intermediarios */}
                                    <MenuIntermediarios roles={roles} />

                                    {// CONDICIONAL
                                    /* Comprobación de la Muestra de Funciones Específicas para los Roles Permitidos */
                                    checkRoles(roles, 6) && (
                                        <div className='text-right top-16'>
                                            <div className='relative inline-block text-left'>
                                                {/* Referencias */}
                                                <Link className='bg-snow flex items-center flex-nowrap text-blue hover:bg-white px-3 py-2.5 rounded-md text-sm font-medium peer xl:py-2' to='/referencias'>
                                                    <MdPayments className='mr-0 xl:mr-1' color={vColors.blue} size={20} />

                                                    <span className='hidden xl:block'>Referencias</span>
                                                </Link>

                                                <div className="px-2 py-0.5 mt-3.5 z-10 absolute bg-blue-2 rounded-full opacity-0 text-white text-sm font-medium peer-hover:duration-300 xl:hidden peer-hover:opacity-100">Referencias</div>
                                            </div>
                                        </div>
                                    )}

                                    {// CONDICIONAL
                                    /* Comprobación de la Muestra de Funciones Específicas para los Roles Permitidos */
                                    checkRoles(roles, 1) && (
                                        <div className='text-right top-16'>
                                            <div className='relative inline-block text-left'>
                                                {/* Impuestos */}
                                                <Link className='bg-snow flex items-center flex-nowrap text-blue hover:bg-white px-3 py-3 rounded-md text-sm font-medium peer xl:py-2' to='/impuestos'>
                                                    <FontAwesomeIcon className='mr-0 xl:mr-1' color={vColors.blue} icon='percentage' />
                                                        
                                                    <span className='hidden xl:block'>Impuestos</span>
                                                </Link>

                                                <div className="px-2 py-0.5 mt-3.5 z-10 absolute bg-blue-2 rounded-full opacity-0 text-white text-sm font-medium peer-hover:duration-300 xl:hidden peer-hover:opacity-100">Impuestos</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='hidden md:block'>
                            <div className='flex flex-nowrap items-center'>
                                <div className='pr-4 top-16 flex flex-nowrap items-center text-right xl:block'>
                                    <div className='mr-2 hidden relative text-left lg:inline-block'>
                                        <div className='px-3 py-1.5 bg-snow rounded-md text-blue text-sm font-bold whitespace-nowrap'>
                                            Patio: <span className='text-purple-2'>{ patio.id === 4 ? 'Patio Externo' : patio.nombre } - { patio.uso }</span>
                                        </div>
                                    </div>
                                    <div className='relative inline-block text-left'>
                                        <Link className='px-3 py-2 bg-snow rounded-md text-blue text-sm font-bold whitespace-nowrap hover:bg-white' to='/tasa'>
                                            Tasa: <span className='text-emerald-darker'>Bs. { numFormat(divisa) }</span>
                                        </Link>
                                    </div>
                                </div>

                                {/* Menú de Usuario */}
                                <MenuUser
                                    data={data}
                                    handleLogout={handleLogout}
                                    open={openModal}
                                    setOpen={setOpenModal}
                                />
                            </div>
                        </div>

                        {/* Botón de Apertura del Menú (Móvil) */}
                        <div className='-mr-2 flex md:hidden'>
                            <button className='bg-snow inline-flex items-center justify-center p-2 rounded-md text-blue-dark focus:outline-none focus:ring-0' onClick={() => setOpenMobile(!openMobile)}>
                                <span className='sr-only'>Menu principal</span>

                                {// CONDICIONAL
                                /* Comprobación del Estado de Apertura del Menú Móvil */
                                openMobile 
                                    ? (<XIcon className='block h-5 w-5' aria-hidden='true' />) 
                                    : (<MenuIcon className='block h-5 w-5' aria-hidden='true' />)
                                }
                            </button>
                        </div>
                    </div>
                    {/* Escritorio (Fin) */}
                </div>

                {// CONSTANTES
                /* Comprobación del Estado de Apertura del Menú Móvil */
                openMobile && (
                    <div className='md:hidden'>
                        <div className='px-2 pt-2 pb-3 space-y-1 sm:px-3'>
                            <MenuMobile
                                open={openMobile}
                                setOpen={setOpenMobile}
                                patio={patio}
                                roles={roles}
                            />
                        </div>
                        <div className='pt-4 pb-3 border-t border-white'>
                            <div className='flex items-center px-5'>
                                <div className='flex-shrink-0'>
                                    <img alt='Usuario' className='h-10 w-10 rounded-full' src={userImg.imageUrl} />
                                </div>
                                <div className='ml-3'>
                                    <div className='text-base font-medium leading-none text-white mt-1'>{ user.name }</div>
                                    <div className='mt-2 text-sm font-medium leading-none text-gray'>{ user.email }</div>
                                    <div className='mt-1 text-sm font-medium leading-none text-gray'>
                                        <span className='font-bold'>Patio:</span> { patio.nombre } - { patio.uso }
                                    </div>
                                </div>

                                {/* Botón de Acción */}
                                <button 
                                    className='p-1 ml-auto flex-shrink-0 rounded-full focus:outline-none focus:ring-0'
                                    onClick={() => setOpenModal(!openModal)}
                                    type='button'
                                >
                                    <span className='sr-only'>Cambio de Patio</span>

                                    <MdPlace className='h-7 w-7 text-violet' aria-hidden='true' />
                                </button>
                            </div>
                            <div className='mt-4 px-2 space-y-1'>
                                <Link
                                    className='block px-4 py-2 text-sm text-ruby font-bold'
                                    onClick={() => handleLogout()}
                                    to={'#'}
                                >
                                    Salir <FontAwesomeIcon className='ml-1' color={vColors.ruby} icon='sign-out-alt' />
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </nav>
        </div>

        {/* Modal para el Cambio de Instancia */}
        <ModalCambioInstancia open={openModal} setOpen={setOpenModal} />
    </>);
}
