import { Fragment } from 'react';
/* Components */
import { ModalCambioInstanciaContent } from './ModalCambioInstanciaContent';
/* Services */
import CookieService from '../../../services/CookieService';
/* Headless UI */
import { Dialog, Transition } from '@headlessui/react';

// COMPONENTE
/* Modal para el Cambio de Instancia */
export const ModalCambioInstancia = ({ isLogin = false, open, setOpen, payload }) => {
    /* Contexto */
    const { user } = CookieService.get('user');
    
    // RETORNO
    return (
        <Transition.Root as={Fragment} show={open}>
            <Dialog
                as='div'
                className='fixed z-50 inset-0 overflow-y-auto'
                onClose={setOpen}
            >
                <div className="-mt-72 md:mt-0 px-4 pt-4 pb-20 min-h-screen flex items-end justify-center text-center sm:p-0 sm:block">
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-300'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40 transition-opacity' />
                    </Transition.Child>

                    {// CONDICIONAL
                    /* Comprobación del Uso para el Login */
                    isLogin
                        ? (
                            <div className="fixed z-30 inset-0 overflow-y-auto">
                                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                                    {// CONDICIONAL
                                    /* Comprobación de la Existencia del Payload de la Acción */
                                    Object.keys(payload).length !== 0 && (
                                        <ModalCambioInstanciaContent
                                            empresas={payload.user.empresas}
                                            isLogin={isLogin}
                                            patios={payload.user.patios}
                                            payload={payload}
                                            setOpen={setOpen}
                                        />
                                    )}
                                </div>
                            </div>
                        )
                        : (<>
                            <span aria-hidden="true" className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

                            <ModalCambioInstanciaContent
                                empresas={user.empresas}
                                isLogin={isLogin}
                                patios={user.patios}
                                setOpen={setOpen}
                            />
                        </>)
                    }
                </div>
            </Dialog>
        </Transition.Root>
    );
}
