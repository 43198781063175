/* Icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AiFillBank } from 'react-icons/ai';
import { BsTagsFill } from 'react-icons/bs';
import { FaBuilding, FaClipboardList, FaMoneyBillWave, FaMugHot, FaVest } from 'react-icons/fa';
import { FiDollarSign } from 'react-icons/fi';
import { HiCash, HiClipboardList, HiDocumentReport } from 'react-icons/hi';
import { MdAccountBalanceWallet, MdOutlineStickyNote2, MdPayments, MdPlace } from 'react-icons/md';
import { TbFileDollar } from 'react-icons/tb';
/* Constants */
import { images } from '../../constants/images';
import { vColors } from './../vColors';

// CONSTANTES
/* Arrays de Opciones para el Menú de Personas */
export const interArray = (color = '', size = 30) => [
    {
        description: 'Registrar los distintos clientes',
        href: '/clientes',
        icon: () => (<FaMugHot color={color !== '' ? color : vColors.redwine} size={size} />),
        name: 'Clientes',
    },
    {
        description: 'Registrar los distintos agentes de aduana',
        href: '/agentes-aduana',
        icon: () => (<FaVest color={color !== '' ? color : vColors.mint} size={size} />),
        name: 'Agentes/Aduana',
    },
]

/* Arrays de Opciones para el Menú de Configuraciones */
export const configArray = (color = '', size = 30) => [
    {
        description: 'Registrar tus tarifas',
        href: '/tarifas',
        icon: () => (<HiCash color={color !== '' ? color : vColors.purple} size={size} />),
        name: 'Tarifas',
        role: 4,
    },
    {
        description: 'Registrar los distintos métodos de pago',
        href: '/pagos',
        icon: () => (<FaMoneyBillWave color={color !== '' ? color : vColors.green} size={size} />),
        name: 'Métodos de Pago',
        role: 1,
    },
    {
        description: 'Registrar los distintos bancos',
        href: '/bancos',
        icon: () => (<AiFillBank color={color !== '' ? color : vColors.blue} size={size} />),
        name: 'Bancos',
        role: 1,
    },
    {
        description: 'Manipulación de la tasa del dólar',
        href: '/tasa',
        icon: () => (<FiDollarSign color={color !== '' ? color : vColors.green} size={size} />),
        name: 'Tasa del Dólar',
        role: 5,
    },
    {
        description: 'Registrar las distintas empresas',
        href: '/empresas',
        icon: () => (<FaBuilding color={color !== '' ? color : vColors.violet} size={size} />),
        name: 'Empresas',
        role: 0,
    },
    {
        description: 'Asociar patios y empresas a los diferentes usuarios',
        href: '/patios-empresas-usuarios',
        icon: () => (<MdPlace color={color !== '' ? color : vColors.blueCobalt} size={size} />),
        name: 'Patios y Empresas a Usuarios',
        role: 0,
    },
]

/* Arrays de Opciones para el Menú de Facturación */
export const factArray = [
    {
        href: '/facturas',
        icon: () => (<HiClipboardList size={20} />),
        name: 'Facturas',
    },
    {
        href: '/preliquidaciones',
        icon: () => (<HiDocumentReport size={20} />),
        name: 'Preliquidación',
    },
    {
        href: '/notas-credito',
        icon: () => (<MdOutlineStickyNote2 size={20} />),
        name: 'Notas de Crédito',
    },
    {
        href: '/avisos-credito',
        icon: () => (<FaClipboardList size={20} />),
        name: 'Avisos de Crédito',
    },
    {
        href: '/referencias',
        icon: () => (<MdPayments size={20} />),
        name: 'Referencias',
    },
]

/* Arrays de Opciones para el Menú de Facturación Manual */
export const factManualArray = [
    {
        href: '',
        icon: () => (<HiClipboardList size={20} />),
        name: 'Facturas',
    },
    {
        href: '/notas-credito',
        icon: () => (<MdOutlineStickyNote2 size={20} />),
        name: 'Notas de Crédito',
    },
    {
        href: '/avisos-credito',
        icon: () => (<FaClipboardList size={20} />),
        name: 'Avisos de Crédito',
    },
    {
        href: '/conceptos',
        icon: () => (<BsTagsFill size={20} />),
        name: 'Conceptos',
    },
    {
        href: '/pagos',
        icon: () => (<FaMoneyBillWave size={20} />),
        name: 'Pagos',
    },
    {
        href: '/cobranzas',
        icon: () => (<MdAccountBalanceWallet size={20} />),
        name: 'Cobranzas',
    },
]

/* Arrays de Opciones para el Menú Móvil */
export const mobileArray = [
    {
        header: 1,
        icon: () => (<FontAwesomeIcon className='mr-2' color={vColors.blueLight} icon='calculator' />),
        name: 'Facturación',
        role: 4,
        submenu: factArray,
    },
    {
        header: 2,
        icon: () => (<TbFileDollar className='-ml-1 mr-1 w-6 h-6' color={vColors.blueLight} icon='calculator' />),
        name: 'Facturación Manual',
        role: 3,
        submenu: factManualArray,
    },
    {
        header: 3,
        icon: () => (<FontAwesomeIcon className='mr-2' color={vColors.blueLight} icon='cogs' />),
        name: 'Configuración',
        role: 5,
        submenu: configArray(vColors.white, 20),
    },
    {
        header: 4,
        icon: () => (<FontAwesomeIcon className='mr-2' color={vColors.blueLight} icon='user' />),
        name: 'Intermediarios',
        role: 5,
        submenu: interArray(vColors.white, 20),
    },
    {
        href: '/referencias',
        icon: () => (<MdPayments className='mr-1 inline-flex' color={vColors.blueLight} />),
        name: 'Referencias',
        role: 6,
        submenu: null,
    },
    {
        href: '/impuestos',
        icon: () => (<FontAwesomeIcon className='mr-2' color={vColors.blueLight} icon='percentage' />),
        name: 'Impuestos',
        role: 1,
        submenu: null,
    },
]

/* Imagen de Usuario */
export const userImg = { imageUrl: images.userBlanco }
